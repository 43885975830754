import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import OrderSuccess from "../views/OrderSuccess.vue";
import OrderCancelled from "../views/OrderCancelled.vue";
import Redeem from "../views/Redeem.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/redeem",
    name: "Redeem",
    component: Redeem,
  },
  {
    path: "/order-success",
    name: "OrderSuccess",
    component: OrderSuccess,
  },
  {
    path: "/order-cancelled",
    name: "OrderCancelled",
    component: OrderCancelled,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
