





























































import Vue from "vue";

import { InterfaceService } from "@/store";
import { ISnackbarMessage } from "@/types";

export default Vue.extend({
  data: () => ({
    show: false as boolean,
  }),
  methods: {
    // Example code on how to activate this component from the outside:
    // setSnack() {
    //   InterfaceService.setSnackbarMessage({
    //     type: "error",
    //     title: "Test",
    //     message: "This is a succesfully displayed test message",
    //   });
    // },
    determineSnackbarColor(snackbarType: ISnackbarMessage["type"]) {
      switch (snackbarType) {
        case "success":
          return "green";
          break;
        case "info":
          return "blue";
          break;
        case "error":
          return "red";
          break;
        case "neutral":
          return "dark-grey";
          break;
      }
    },
    determineSnackbarIcon(snackbarType: ISnackbarMessage["type"]) {
      switch (snackbarType) {
        case "success":
          return "mdi-check-circle";
          break;
        case "info":
          return "mdi-information";
          break;
        case "error":
          return "mdi-alert";
          break;
        case "neutral":
          return "";
          break;
      }
    },
  },
  computed: {
    snackbarMessage() {
      return InterfaceService.snackbarMessage;
    },
  },
  watch: {
    snackbarMessage() {
      this.show = true;
    },
  },
});
