


















































import Vue from "vue";

import AppSnackbar from "@/components/layout/AppSnackbar.vue";

export default Vue.extend({
  components: { AppSnackbar },
  name: "App",
  data: () => ({
    //
  }),
});
