<template>
  <ValidationProvider :name="$attrs.label" :rules="rules" :vid="$attrs.vid">
    <!-- NOTE: vid is necessary when targetting certain elements -->
    <!-- slot-scope="{ errors, valid }" -->
    <v-text-field
      style="padding-top: 4px"
      slot-scope="{ errors }"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-text-field>
    <!-- :success="valid" -->
  </ValidationProvider>
</template>

<script>
//Referenced from Vee-validate code here: https://codesandbox.io/s/mjy97x85py
// import { ValidationProvider } from "vee-validate";
export default {
  //      components: {
  //     ValidationProvider
  //   },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
