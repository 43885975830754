



























































































































































































































































interface SubscriptionOptions {
  label: string;
  price: number;
  value: string;
}

import Vue from "vue";

import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";

import { ValidationObserver } from "vee-validate";
import { InterfaceService } from "@/store";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation.vue";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation.vue";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation.vue";

import { IRecipient } from "@/types";

export default Vue.extend({
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VTextAreaWithValidation,
  },
  created() {
    this.selectedSubscription = this.subscriptions[1];
  },
  data() {
    return {
      loading: false,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      step: 1,
      subscriptions: [
        {
          label: "Premium: 6 Month",
          price: 11.99,
          value: "six_month",
        },
        {
          label: "Premium: 12 Month",
          price: 19.99,
          value: "yearly",
        },
      ] as SubscriptionOptions[],
      selectedSubscription: {} as SubscriptionOptions,
      buyer: {
        name: "",
        email: "",
      },
      recipients: [
        {
          name: "",
          email: "",
          giftMessage: "",
        },
      ] as IRecipient[],
    };
  },
  methods: {
    async validateStep(stepNumber: number) {
      if (stepNumber == 1) {
        const validStep1 = await (this.$refs.buyerInfoForm as InstanceType<
          typeof ValidationObserver
        >).validate();

        if (validStep1) this.step = 2;
      } else if (stepNumber == 2) {
        const validStep2 = await (this.$refs.recipientsInfoForm as InstanceType<
          typeof ValidationObserver
        >).validate();

        if (validStep2) this.step = 3;
      } else {
        throw Error("Step number is invalid");
      }
    },
    addRecipient() {
      const newRecipient: IRecipient = {
        name: "",
        email: "",
        giftMessage: "",
      };
      this.recipients.push(newRecipient);
    },
    removeRecipient(index: number) {
      // Remove recipient element from array
      this.recipients.splice(index, 1);
    },

    async submitOrderForCheckout() {
      try {
        this.loading = true;
        const result = await axios.post(
          `${process.env.VUE_APP_BACKEND_API_URL}/createCheckoutSession`,
          this.buildOrder()
        );
        const stripeSessionID = result.data.id;

        const stripe = await loadStripe(
          process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
        );

        // When the customer clicks on the button, redirect them to Checkout.
        const stripeResult = await stripe?.redirectToCheckout({
          sessionId: stripeSessionID,
        });

        if (stripeResult?.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          throw Error(stripeResult?.error?.message?.toString());
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        InterfaceService.setSnackbarMessage({
          type: "error",
          title: "Error loading checkout",
          message: err,
          dismissible: true,
          persistent: true,
        });
        // console.error(err);
      }
    },
    buildOrder() {
      const order = {
        buyer: {},
        recipients: {},
        selectedSubscription: {},
      };

      order.buyer = this.buyer;
      order.recipients = this.recipients;

      if (!this.selectedSubscription?.value)
        throw Error("Subscription must be selected!");
      else {
        // @ts-nocheck
        order.selectedSubscription = this.selectedSubscription.value;
      }

      return order;
    },
  },
});
