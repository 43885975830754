import Vue from "vue";
import Vuex, { Module, Store } from "vuex";

// We are using this package to allow ES6/7 class syntax for store modules that are dynamically imported
// https://championswimmer.in/vuex-module-decorators/pages/getting-started.html#define-a-module

import { getModule } from "vuex-module-decorators";

import InterfaceModule from "@/store/modules/interface";

Vue.use(Vuex);

const store: Store<any> = new Vuex.Store({
  modules: {
    InterfaceModule,
  },
});

export default store;

// Used for general UI related state/tasks
export const InterfaceService = getModule(InterfaceModule, store);
