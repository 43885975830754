// This file sets the validation rules for VeeValidate (You can create any custom rule: https://logaretm.github.io/vee-validate/guide/rules.html#rules)

import { extend } from "vee-validate";

import {
  required,
  email,
  confirmed,
  integer,
  regex,
  min,
  min_value,
  max,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: `The {_field_} field is required`,
});

extend("email", {
  ...email,
  message: `Please enter a valid email address`,
});

extend("integer", {
  ...integer,
  message: `Please enter a valid number (0-100, etc)`,
});

extend("confirmed", {
  ...confirmed,
  message: `Passwords must match`,
});

extend("min", {
  ...min,
  message: "The {_field_} field must have at least {length} characters",
});

extend("min_value", {
  ...min_value,
  message: "The {_field_} field must have a value of 1 or higher",
});

extend("max", {
  ...max,
  message: "The {_field_} field has exceeded the max allowed length",
});

// Acts as a form of the 'Required' rule but for inputs that need to receive an object as a selection
extend("nonEmptyObject", {
  validate: (value) => {
    return !(
      Object.entries(value).length === 0 && value.constructor === Object
    );
  },
  message: "The {_field_} field must have an option selected",
});

// extend("validUrl", {
//   validate: (value) => {
//     return validateUrl(value);
//   },
//   message:
//     "Invalid URL. Try pasting your link into another browser window and see if it works. There are multiple reasons it could be wrong, but make sure it has https:// or http:// at the start  ",
// });

extend("strongPassword", {
  validate: (value) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
  message:
    "Password must consist of at least 1 lower and upper case letter, 1 digit, and 1 special character (E.g. , . _ # $ @ & ? etc),",
});
