import { ISnackbarMessage } from "@/types";

import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
  MutationAction,
  config,
} from "vuex-module-decorators";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

const moduleName = "InterfaceModule";

@Module({ name: moduleName })
/** Module for toggling various parts of the user interface */
class InterfaceModule extends VuexModule {
  // SNACKBAR
  snackbarMessage = {} as ISnackbarMessage;

  @Mutation
  async setSnackbarMessage(snackbarMessage: ISnackbarMessage) {
    this.snackbarMessage = snackbarMessage;
  }

  //LOADING INDICATOR
  // showLoading = false;

  // @Mutation
  // setLoadingStatus(loading: boolean) {
  //   this.showLoading = loading;
  // }
}

export default InterfaceModule;
