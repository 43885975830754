



































































































































import Vue from "vue";

import { VueMaskDirective } from "v-mask";

import axios from "axios";

import { ValidationObserver } from "vee-validate";

import { InterfaceService } from "@/store/index";

import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation.vue";

export default Vue.extend({
  directives: { mask: VueMaskDirective },
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
  },
  created() {
    console.log(this.$route.query);

    const email = this.$route.query["email"];
    const subscriptionCode = this.$route.query["subscriptionCode"];

    this.email = String(email ?? "");
    this.subscriptionCode = String(subscriptionCode ?? "");
  },
  data: () => ({
    loading: false,
    email: "",
    subscriptionCode: "",
    // https://stackoverflow.com/questions/19989481/how-to-determine-if-a-string-is-a-valid-v4-uuid
    activationCodeMask: "XXXX-XXXX-XXXX-XXXX",
  }),
  methods: {
    async redeemCode() {
      const { email, subscriptionCode } = this;

      const valid = await (this.$refs.activationForm as InstanceType<
        typeof ValidationObserver
      >).validate();

      try {
        if (valid) {
          this.loading = true;
          await axios.post(
            `${process.env.VUE_APP_BACKEND_API_URL}/redeemSubscriptionCode`,
            {
              email,
              subscriptionCode,
            }
          );

          InterfaceService.setSnackbarMessage({
            type: "success",
            title: "Subscription Activated!",
            message:
              "Your gift subscription was successfully activated. Make sure to reload the app to see your new membership.",
            dismissible: true,
            persistent: true,
          });

          this.loading = false;
        }
      } catch (e) {
        this.loading = false;

        InterfaceService.setSnackbarMessage({
          type: "error",
          title: "Error Activating Subscription",
          message: e.response.data,
          dismissible: true,
          persistent: true,
        });
        console.error(e);
      }
    },
  },
});
