import Vue from "vue";
// Sentry error tracking
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

// Google Tag Manager
import VueGtm from "vue-gtm";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://2a3943faf3f0494ab433e77b8f221c31@o485138.ingest.sentry.io/5539844",
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        logErrors: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

//Vee-validate validation rules
import "@/validation";

//Vee-validate form validation
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);

import * as rules from "vee-validate/dist/rules";

Vue.config.productionTip = false;

// Vue.component("StripeCheckout", StripeCheckout);

// id: "GTM-WDW9CQ9"

Vue.use(VueGtm, {
  id: "GTM-WDW9CQ9", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview:'env-4',
  //   gtm_cookies_win:'x'
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV == "development" ? true : false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
